import React, {useState, useEffect, FormEvent} from 'react';
import "../PosterGenerator.sass";
import {PosterFormData} from "./PosterFormData";
import { settingsCaracteres} from "./PosterValues";
import {handleSubmitGenererPoeme, handleSubmitGenererPoster} from "../../Service";
import {PosterForm} from "./PosterForm";
import {GenericGeneratorLayout} from "../GenericGeneratorLayout";
import {PoemForm} from "../poem/PoemForm";

const PosterGenerator = () => {
    const [curSettings, setCurSettings] = useState(0);
    const [ formData, setFormData] = useState<PosterFormData>(settingsCaracteres[curSettings]);
    const [posterPath, setPosterPath] = useState("");

    useEffect(() => {
        setFormData(settingsCaracteres[curSettings])
    }, [curSettings]);

    const handleSubmitForm = async (e: FormEvent) => {
        e.preventDefault();
        await handleSubmitGenererPoster(setPosterPath, formData);
    };

    return (<GenericGeneratorLayout posterPath={posterPath} handleSubmit={handleSubmitForm} originalHeight={formData.hauteur_poster} originalWidth={formData.largeur_poster}formElement={<PosterForm formData={formData} setFormData={setFormData} curSettings={curSettings} setCurSettings={setCurSettings}/>}/>);
};

export default PosterGenerator;