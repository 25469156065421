import React from "react";

const FormField = (props: {className: string, type: string, value:string | number, id: string, handleChange: React.ChangeEventHandler<HTMLInputElement> | undefined}) => {
    return(
        <input className={props.className} type={props.type} id={props.id} name={props.id} value={props.value}
                       onChange={props.handleChange}/>
   )
}

export default FormField;
