import FontSelectionInput from "../../components/rows/FontSelectionInput";
import NumericInput from "../../components/rows/NumericInput";
import ColorPickerInput from "../../components/rows/ColorPickerInput";
import React, {ChangeEvent, useState} from "react";
import translations from "../../../languages/Translations";
import {useLangue} from "../../../LangueContexte";
import {PosterFormData} from "./PosterFormData";
import {IoIosArrowDropleft, IoIosArrowDropright} from "react-icons/io";
import {settingsCaracteres} from "./PosterValues";
import {ButtonsSelection} from "../../components/rows/ButtonsSelection";


export const PosterForm = (props: {formData: PosterFormData, setFormData: Function, curSettings: number, setCurSettings: Function}) => {

    const { langue, translations, changerLangue } = useLangue();

    const handleChangeFont = (e: ChangeEvent<HTMLSelectElement>) => {
        const { name, value } = e.target;
        props.setFormData((prevData: any) => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleColorChange = (name: keyof PosterFormData) => (e: ChangeEvent<HTMLInputElement>) => {
        props.setFormData((prevData: any) => ({
            ...prevData,
            [name]: e.target.value
        }));
    };

    const handleNumericChange = (name: keyof PosterFormData) => (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        const isValidNumber = /^[+-]?([0-9]*[.])?[0-9]+$/.test(value);

        if (isValidNumber || value === "") {
            props.setFormData((prevData: any) => ({
                ...prevData,
                [name]: value === "" ? "" : parseFloat(value),
            }));
        }
    };

    function handleClickButton() {
        props.setCurSettings((props.curSettings + 1) % settingsCaracteres.length);
    }

    return (<>
        <FontSelectionInput label={translations.police} font={props.formData.police}
                          handleChangeFont={handleChangeFont}/>
    <NumericInput label={translations.taille_police_pinyin} value={props.formData.taille_police_pinyin}
                     id={"taille_police_pinyin"}
                     handleChange={handleNumericChange("taille_police_pinyin")}/>
    <NumericInput label={translations.taille_police_caractere}
                     value={props.formData.taille_police_caractere} id={"taille_police_caractere"}
                     handleChange={handleNumericChange("taille_police_caractere")}/>
    <NumericInput label={translations.nombre_cellules_largeur}
                     value={props.formData.nombre_cellule_largeur} id={"nombre_cellule_largeur"}
                     handleChange={handleNumericChange("nombre_cellule_largeur")}/>
    <NumericInput label={translations.nombre_cellules_hauteur}
                     value={props.formData.nombre_cellule_hauteur} id={"nombre_cellule_hauteur"}
                     handleChange={handleNumericChange("nombre_cellule_hauteur")}/>
    <NumericInput label={translations.marge} value={props.formData.margin} id={"margin"}
                     handleChange={handleNumericChange("margin")}/>
    <NumericInput label={translations.largeur_poster} value={props.formData.largeur_poster}
                     id={"largeur_poster"} handleChange={handleNumericChange("largeur_poster")}/>
    <NumericInput label={translations.hauteur_poster} value={props.formData.hauteur_poster}
                     id={"hauteur_poster"} handleChange={handleNumericChange("hauteur_poster")}/>
    <ColorPickerInput label={translations.couleur_haut_gauche} color={props.formData.color_hg}
                    id={"color_hg"} handleChange={handleColorChange("color_hg")}/>
    <ColorPickerInput label={translations.couleur_bas_gauche} color={props.formData.color_bg}
                    id={"color_bg"} handleChange={handleColorChange("color_bg")}/>
    <ColorPickerInput label={translations.couleur_haut_droite} color={props.formData.color_hd}
                    id={"color_hd"} handleChange={handleColorChange("color_hd")}/>
    <ColorPickerInput label={translations.couleur_bas_droite} color={props.formData.color_bd}
                    id={"color_bd"} handleChange={handleColorChange("color_bd")}/>
    <ColorPickerInput label={translations.couleur_fond} color={props.formData.background_color_1}
                    id={"background_color_1"}
                    handleChange={handleColorChange("background_color_1")}/>
    <ButtonsSelection onClick={handleClickButton}/>
        </>

    )
}