import React, { ChangeEvent } from 'react';
import Col from 'react-bootstrap/Col';

import Label from "../Label";
import Row from "react-bootstrap/Row";

interface FontSelectionInputProps {
    font: string;
    handleChangeFont: (e: ChangeEvent<HTMLSelectElement>) => void;
    label: string;
}

const FontSelectionInput: React.FC<FontSelectionInputProps> = ({ label, font, handleChangeFont }) => {
    return (
        <div className={"form-poster-field"}>
                <Label className={"form-label"} text={label} />
                <select className={"form-field clickable-pointer"} id={"police"} name={"police"} value={font} onChange={handleChangeFont}>
                    <option value="FZFangSong-Z02S Regular.ttf">FZFangSong Z02S Regular</option>
                    <option value="FZKai-Z03 Regular.ttf">FZKai Z03 Regular</option>
                    <option value="Fontquan-XinYiJiXiangSong Regular.ttf">Fontquan XinYiJiXiangSong Regular</option>
                    <option value="HanyiSentyPagoda Regular.ttf">HanyiSentyPagoda Regular</option>
                    <option value="XiaolaiMonoSC-Regular.ttf">XiaolaiMonoSC Regular</option>
                    <option value="XiaolaiSC-Regular.ttf">XiaolaiSC Regular</option>
                    <option value="YRDZST Medium.ttf">YRDZST Medium</option>
                    <option value="NotoSansTC-Black.ttf">NotoSansTC Black</option>
                    <option value="NotoSansSC-VariableFont_wght.ttf">NotoSansSC-VariableFont_wght</option>
                    <option value="NotoSansSC-Black.ttf">NotoSansSC Black</option>
                    <option value="NotoSansSC-Bold.ttf">NotoSansSC Bold</option>
                    <option value="NotoSansSC-ExtraBold.ttf">NotoSansSC ExtraBold</option>
                    <option value="NotoSansSC-ExtraLight.ttf">NotoSansSC ExtraLight</option>
                    <option value="NotoSansSC-Light.ttf">NotoSansSC Light</option>
                    <option value="NotoSansSC-Medium.ttf">NotoSansSC Medium</option>
                    <option value="NotoSansSC-Regular.ttf">NotoSansSC Regular</option>
                    <option value="NotoSansSC-SemiBold.ttf">NotoSansSC SemiBold</option>
                    <option value="NotoSansSC-Thin.ttf">NotoSansSC Thin</option>
                </select>
        </div>
    );
};

export default FontSelectionInput;
