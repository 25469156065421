import FontSelectionInput from "../../components/rows/FontSelectionInput";
import NumericInput from "../../components/rows/NumericInput";
import ColorPickerInput from "../../components/rows/ColorPickerInput";
import React, {ChangeEvent, useState} from "react";
import {useLangue} from "../../../LangueContexte";
import {settingsCaracteres} from "../poster/PosterValues";
import {ButtonsSelection} from "../../components/rows/ButtonsSelection";
import {PoemeFormData} from "./PoemeFormData";
import PoemeSelectionInput from "../../components/rows/PoemeSelectionInput";
import {settings_poeme} from "./PoemeValues";


export const PoemForm = (props: {formData: PoemeFormData, setFormData: Function, curSettings: number, setCurSettings: Function}) => {

    const { langue, translations, changerLangue } = useLangue();

    const handleChangeFont = (e: ChangeEvent<HTMLSelectElement>) => {
        const { name, value } = e.target;
        props.setFormData((prevData: any) => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleColorChange = (name: keyof PoemeFormData) => (e: ChangeEvent<HTMLInputElement>) => {
        props.setFormData((prevData: any) => ({
            ...prevData,
            [name]: e.target.value
        }));
    };

    const handleNumericChange = (name: keyof PoemeFormData) => (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        const isValidNumber = /^[+-]?([0-9]*[.])?[0-9]+$/.test(value);

        if (isValidNumber || value === "") {
            props.setFormData((prevData: any) => ({
                ...prevData,
                [name]: value === "" ? "" : parseFloat(value),
            }));
        }
    };

    function handleClickButton() {
        props.setCurSettings((props.curSettings + 1) % settings_poeme.length);
    }

    function handleChangePoeme(e: ChangeEvent<HTMLSelectElement>) {
        const { name, value } = e.target;
        props.setFormData((prevData: any) => ({
            ...prevData,
            [name]: value
        }));
    }


    return (<>
            <PoemeSelectionInput label={translations.poeme} poeme={props.formData.poeme} handleChangePoeme={handleChangePoeme}/>
            <FontSelectionInput label={translations.police} font={props.formData.police} handleChangeFont={handleChangeFont}/>
            <NumericInput label={translations.taille_police_caractere} value={props.formData.taille_police_caractere}
                          id={"taille_police_caractere"}
                          handleChange={handleNumericChange("taille_police_caractere")}/>
            <NumericInput label={translations.marge} value={props.formData.margin} id={"margin"}
                          handleChange={handleNumericChange("margin")}/>
            <NumericInput label={translations.largeur_poster} value={props.formData.largeur_poster} id={"largeur_poster"}
                          handleChange={handleNumericChange("largeur_poster")}/>
            <NumericInput label={translations.hauteur_poster} value={props.formData.hauteur_poster} id={"hauteur_poster"}
                          handleChange={handleNumericChange("hauteur_poster")}/>
            <ColorPickerInput label={translations.couleur_haut_gauche} color={props.formData.color_hg}
                              id={"color_hg"}
                              handleChange={handleColorChange("color_hg")}/>
            <ColorPickerInput label={translations.couleur_bas_gauche} color={props.formData.color_bg}
                              id={"color_bg"}
                              handleChange={handleColorChange("color_bg")}/>
            <ColorPickerInput label={translations.couleur_haut_droite} color={props.formData.color_hd}
                              id={"color_hd"}
                              handleChange={handleColorChange("color_hd")}/>
            <ColorPickerInput label={translations.couleur_bas_droite} color={props.formData.color_bd}
                              id={"color_bd"}
                              handleChange={handleColorChange("color_bd")}/>
            <ColorPickerInput label={translations.couleur_fond} color={props.formData.background_color_1}
                              id={"background_color_1"}
                              handleChange={handleColorChange("background_color_1")}/>
            <ButtonsSelection onClick={handleClickButton}/>
        </>

    )
}