import React from "react";
import {moveFile} from "../../Service";
import {GrGallery} from "react-icons/gr";


const BouttonAjouterGalerieRow = (props: { filename: string, label: string }) => {
    return (
        <button onClick={() => {
            moveFile(props.filename)
        }} disabled={true} className={"bouton-telecharger clickable-pointer"}><GrGallery/> {props.label}</button>
    );
};

export default BouttonAjouterGalerieRow;
