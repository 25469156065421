import React, { ChangeEvent } from 'react';
import Col from 'react-bootstrap/Col';
import Label from "../Label";
import FormField from "../FormField";
import Row from "react-bootstrap/Row";

interface NumericInputProps {
    label: string;
    value: number;
    id: string;
    handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

const NumericInput: React.FC<NumericInputProps> = ({ label, value, id, handleChange }) => {
    return (
        <div className={"form-poster-field"}>
            <Label className={"form-label"} text={label} />
                <FormField className={"form-field"} type={"text"} value={value} id={id} handleChange={handleChange} />
        </div>
    );
};

export default NumericInput;
