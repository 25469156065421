import React, {useContext} from "react";
import franceFlag from '../../resources/flag_france.png'
import chinaFlag from '../../resources/flag_china.png'
import germanyFlag from '../../resources/flag_germany.png'
import ukFlag from '../../resources/flag_uk.png'
import {useLangue} from "../../LangueContexte";





const Header: React.FC = () => {

    const { langue, translations, changerLangue } = useLangue();

    function Flag(props: { imagePath: string, language: string }) {
        return <div onClick={() => changerLangue(props.language)} className={"flag-container"}><img className={"flag"} src={props.imagePath} alt={""}/></div>;
    }


    function Flags() {
        return <div className={"flags"}>
            <div>
                <Flag language={"de"} imagePath={germanyFlag}/>
                <Flag language={"en"} imagePath={ukFlag}/>
            </div>
            <div>
                <Flag language={"fr"} imagePath={franceFlag}/>
                <Flag language={"zh"} imagePath={chinaFlag}/>

            </div>


        </div>;
    }

    return (<div className={"header"}>
        <div className={"header-title"}>BAMU</div>
        {/*<Flags/>*/}
    </div>)
}

export default Header