import React, {useEffect, useState} from 'react';
import "./generator/PosterGenerator.sass";
import {IoIosArrowDropleft, IoIosArrowDropright} from "react-icons/io";
import Row from "react-bootstrap/Row";
import {Document, Page, pdfjs} from 'react-pdf';
import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import {SizeMe} from "react-sizeme";
import baseUrl from "./Service";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

function ButtonRow(props: { lol: Function }) {
    return (<Row className={"form-row poster-select"}>
        <div className={"arrows"}>
            <IoIosArrowDropleft className={"bouton-arrow"} onClick={() => props.lol(-1)}/>
            <IoIosArrowDropright className={"bouton-arrow"} onClick={() => props.lol(1)}/>
        </div>
    </Row>)
}

const Galerie: React.FC = () => {
    const [indexPoster, setIndexPoster] = useState(0);
    const [posters, setPosters] = useState([]);

    useEffect(() => {
        const apiUrl = baseUrl + '/liste_posters';
        fetch(apiUrl)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Erreur lors de la récupération de la liste des posters');
                }
                return response.json();
            })
            .then(data => {
                setPosters(data);
            })
            .catch(error => {
                console.error(error);
            });
    }, []);

    function handleClickButton() {
        setIndexPoster((indexPoster + 1) % posters.length);
    }


    return (
        <div className={"container"}>
            <div className={"container-form-preview"}>
                <div className={"container-galerie"}>
                    <SizeMe
                    >
                        {({size}) => (
                            // <div>
                            <Document error={""} loading={""} file={baseUrl + "/" + posters[indexPoster]}>
                                <Page loading={""} canvasBackground={"transparent"} pageNumber={1}
                                      width={size.width ? size.width : undefined}
                                />
                            </Document>
                        )}
                    </SizeMe>
                </div>
            </div>
            <ButtonRow lol={handleClickButton}/>
        </div>
    );
};

export default Galerie;
