import React, {createContext, useState, useContext, ReactNode, useEffect} from 'react';
import Translations from "./languages/Translations";

interface MenuContextType {
    selectedMenu: string | null;
    handleSelectMenu: (menuName: string) => void;
}

const MenuContext = createContext<MenuContextType | undefined>( undefined);

const setSelectedMenuToLocalStorage = (selectedMenu: string | null) => {
    localStorage.setItem('selectedMenu', JSON.stringify(selectedMenu));
};

const getSelectedMenuFromLocalStorage = () => {
    const selectedMenu = localStorage.getItem('selectedMenu');
    return selectedMenu ? JSON.parse(selectedMenu) : null;
};

export const useMenu = () => {
    const context = useContext(MenuContext);
    if (!context) {
        throw new Error('useMenu must be used within a MenuProvider');
    }
    return context;
};

export const MenuProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [selectedMenu, setSelectedMenu] = useState(getSelectedMenuFromLocalStorage());

    useEffect(() => {
        setSelectedMenuToLocalStorage(selectedMenu);
    }, [selectedMenu]);

    const handleSelectMenu = (menuName: string) => {
        setSelectedMenu(menuName);
    };

    return (
        <MenuContext.Provider value={{ selectedMenu, handleSelectMenu }}>
            {children}
        </MenuContext.Provider>
    );
};