import React from "react";
import { FaShoppingCart } from "react-icons/fa";

const BouttonCommanderRow = (props: {label: string}) => {
    return (
            <button disabled={true} onClick={() => {
            }} className={"bouton-telecharger clickable-pointer"}><FaShoppingCart/> {props.label}</button>

    );
};

export default BouttonCommanderRow;
