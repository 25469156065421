import React, { ChangeEvent } from 'react';
import Col from 'react-bootstrap/Col';
import { Row } from 'react-bootstrap';
import Label from "../Label";
import FormField from "../FormField";

interface ColorPickerInputProps {
    label: string;
    color: string;
    id: string;
    handleChange: (e: ChangeEvent<HTMLInputElement>) => void;
}

const ColorPickerInput: React.FC<ColorPickerInputProps> = ({ label, color, id, handleChange }) => {
    return (
        <div className={"form-poster-field"}>
                <Label className={"form-label"} text={label} />
                <FormField className={"form-field clickable-pointer"} type={"color"} value={color} id={id} handleChange={handleChange} />
        </div>
    );
};

export default ColorPickerInput;
