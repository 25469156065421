import "./Domotique.sass"
import React, {useEffect, useState} from "react";
import Button from 'react-bootstrap/Button';
import 'bootstrap/dist/css/bootstrap.min.css';

interface Pompe {
    id: number;
    value: number;
    name: string;
}

const boxUrl = "https://90.60.70.180:8554";
const Domotique: React.FC = () => {
    const [pompes, setPompes] = useState<Pompe[]>([]);
    const [duration, setDuration] = useState<number>(10); // Durée par défaut

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${boxUrl}/get_plants`);
                if (!response.ok) {
                    throw new Error("Erreur lors de la récupération des données");
                }
                const data = await response.json();
                console.log(data);

                const pompesData: Pompe[] = data.map((pompeData: any[]) => ({
                    id: pompeData[0],
                    value: pompeData[1],
                    name: pompeData[2],
                }));
                setPompes(pompesData);
            } catch (error) {
                console.error(error);
            }
        };

        fetchData();
    }, []);


    const handleDurationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDuration(parseInt(event.target.value));
    };

    const handleButtonClicked = async (id: number) => {
        try {
            const response = await fetch(`${boxUrl}/feed_plant?pump=${id}&duration=${duration}`, {
                method: "GET",
            });
            if (!response.ok) {
                throw new Error("Erreur lors de l'appel à l'API");
            }
            // Vous pouvez ajouter du code pour gérer la réponse si nécessaire
        } catch (error) {
            console.error(error);
        }
    };


    return (
        <div className="center-buttons">
            {pompes.map((pompe) => (
                <Button className={"button-pompe"} variant={"primary"} key={pompe.id}
                        onClick={() => handleButtonClicked(pompe.id)}>
                    {pompe.name}
                </Button>
            ))}
            <input type="range" className={"duration-input"} min={1} max={60} value={duration}
                   onChange={handleDurationChange}/>
            <div className="duration-label">{duration} secondes</div>
        </div>
    );
};

export default Domotique;
