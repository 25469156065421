import React, {useState, useEffect, FormEvent} from 'react';
import "../PosterGenerator.sass";
import { settings_poeme } from "./PoemeValues";
import {handleSubmitGenererPoeme, handleSubmitGenererPoster} from "../../Service";
import {GenericGeneratorLayout} from "../GenericGeneratorLayout";
import {PoemForm} from "./PoemForm";
import {PoemeFormData} from "./PoemeFormData";
import {settingsCaracteres} from "../poster/PosterValues";
import {PDFDocumentProxy} from "pdfjs-dist";

const PoemGenerator = () => {
    const [curSettings, setCurSettings] = useState(0);
    const [ formData, setFormData] = useState<PoemeFormData>(settings_poeme[curSettings]);
    const [posterPath, setPosterPath] = useState("");

    useEffect(() => {
        setFormData(settings_poeme[curSettings])
    }, [curSettings]);

    const handleSubmitForm = async (e: FormEvent) => {
        e.preventDefault();
        await handleSubmitGenererPoeme(setPosterPath, formData);
    };

    return (<GenericGeneratorLayout posterPath={posterPath} handleSubmit={handleSubmitForm} originalHeight={formData.hauteur_poster} originalWidth={formData.largeur_poster}formElement={<PoemForm formData={formData} setFormData={setFormData} curSettings={curSettings} setCurSettings={setCurSettings}/>}/>);
};

export default PoemGenerator;