
import React from "react";
import {FaMagic} from "react-icons/fa";

const BouttonTelechargerRow = (props: {label: string, setRightPanelLoaded: Function}) => {
    return (
                <button onClick={() => props.setRightPanelLoaded(true)} type="submit" className={"bouton-telecharger clickable-pointer"}><FaMagic/> {props.label}</button>
                );
};

export default BouttonTelechargerRow;
