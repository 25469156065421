import {PosterFormData} from "./generator/poster/PosterFormData";
import {PoemeFormData} from "./generator/poem/PoemeFormData";

var baseUrl = "";
if (process.env.ENV === "local") {
    baseUrl = "http://localhost:5000";
} else {
    baseUrl = "http://gkzmelp.cluster030.hosting.ovh.net";
}

export default baseUrl;

export const handleSubmitTelechargerPoster = async (formData: PosterFormData) => {
    handleSubmitTelecharger(baseUrl + '/telecharger_poster_caracteres', formData);
}

export const handleSubmitTelechargerPoeme = async (formData: PoemeFormData) => {
    handleSubmitTelecharger(baseUrl + '/telecharger_poster_poeme', formData);
}

const handleSubmitTelecharger = async (url: string, formData: PosterFormData | PoemeFormData) => {
    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(formData)
        });

        if (response.ok) {
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.download = 'Poster.pdf';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            console.log('Poster généré avec succès!');
        } else {
            console.error('Erreur lors de la génération du poster');
        }
    } catch (error) {
        console.error('Erreur lors de la requête POST:', error);
    }
};

export const handleSubmitGenererPoster = async (updateStateFunction: Function, formData: PosterFormData) => {
    handleSubmitGenerer(updateStateFunction, baseUrl + '/generer_poster_caracteres', formData);
}

export const handleSubmitGenererPoeme = async (updateStateFunction: Function, formData: PoemeFormData) => {
    handleSubmitGenerer(updateStateFunction, baseUrl + '/generer_poster_poeme', formData);
}

export const moveFile = async (filename: string) => {
    const url = baseUrl + "/move/" + filename
    try {
        await fetch(url);
    } catch (error) {
    }

}


const handleSubmitGenerer = async (updateStateFunction: Function, url: string, formData: PosterFormData | PoemeFormData) => {
    fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
    })
        .then(response => {
            if (!response.ok) {
                throw new Error('Erreur HTTP, status : ' + response.status);
            }
            return response.text();
        })
        .then(data => {
            updateStateFunction(data);
        })
        .catch(error => console.error('Erreur lors de la récupération des données:', error));
}
