import React, { ChangeEvent } from 'react';
import Col from 'react-bootstrap/Col';

import Label from "../Label";
import Row from "react-bootstrap/Row";

interface PoemeSelectionInputProps {
    poeme: string;
    handleChangePoeme: (e: ChangeEvent<HTMLSelectElement>) => void;
    label: string;
}

const PoemeSelectionInput: React.FC<PoemeSelectionInputProps> = ({ label, poeme, handleChangePoeme }) => {
    return (
        <div className={"form-poster-field"}>

            <Label className={"form-label"} text={label}/>
            <select className={"form-field"} id={"poeme"} name={"poeme"} value={poeme} onChange={handleChangePoeme}>
                <option value="poem-sympathy-for-peasants">Sympathy for peasants</option>
                <option value="poem-on-parting">On parting</option>
                <option value="poem-bmt">BMT</option>
                <option value="poem-allez">Allez tranquillement...</option>
            </select>
        </div>
            )
    ;
};

export default PoemeSelectionInput;
