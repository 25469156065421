import React, {createContext, useState, useContext, ReactNode, useEffect} from 'react';

import frTranslations from './languages/fr.json';
import zhTranslations from './languages/zh.json';
import deTranslations from './languages/de.json';
import enTranslations from './languages/en.json';
import Translations from "./languages/Translations";

interface LangueContexteType {
    langue: string;
    translations: Translations;
    changerLangue: (nouvelleLangue: string) => void;
}

const LangueContexte = createContext<LangueContexteType | undefined>(undefined);


const getLanguageFromLocalStorage = () => {
    const language = localStorage.getItem('language');
    return language ? JSON.parse(language) : null;
};
const setLanguageToLocalStorage = (language: string) => {
    localStorage.setItem('language', JSON.stringify(language));
};

export const LangueProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [langue, setLangue] = useState(getLanguageFromLocalStorage());

    let translations;

    switch(langue) {
        case 'fr':
            translations = frTranslations;
            break;
        case 'en':
            translations = enTranslations;
            break;
        case 'zh':
            translations = zhTranslations;
            break;
        case 'de':
            translations = deTranslations;
            break;
        default:
            translations = frTranslations;
            break;
    }

    useEffect(() => {
        setLanguageToLocalStorage(langue);
    }, [langue]);

    const changerLangue = (nouvelleLangue: string) => {
        setLangue(nouvelleLangue);
    };

    return (
        <LangueContexte.Provider value={{ langue, translations, changerLangue }}>
            {children}
        </LangueContexte.Provider>
    );
};

export const useLangue = () => {
    const context = useContext(LangueContexte);
    if (!context) {
        throw new Error('useLangue doit être utilisé à l\'intérieur de LangueProvider');
    }
    return context;
};

export default LangueContexte;
