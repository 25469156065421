import React, {FormEventHandler, ReactElement, useState} from 'react';
import "./PosterGenerator.sass";
import BouttonTelechargerRow from "../components/rows/BouttonTelechargerRow";
import {useLangue} from "../../LangueContexte";
import {SizeMe} from "react-sizeme";
import {Document, Page} from "react-pdf";
import baseUrl from "../Service";
import BouttonAjouterGalerieRow from "../components/rows/BouttonAjouterGalerieRow";
import BouttonCommanderRow from "../components/rows/BouttonCommandeRow";
import {PDFDocumentProxy} from 'pdfjs-dist';

export const GenericGeneratorLayout = (props: {
    posterPath: string,
    originalHeight: number,
    originalWidth: number,
    formElement: ReactElement,
    handleSubmit: FormEventHandler<HTMLFormElement>
}) => {
    const {langue, translations, changerLangue} = useLangue();
    const [pageHeight, setPageHeight] = useState(0);
    const [pageWidth, setPageWidth] = useState(0);
    const [rightPanelLoaded, setRightPanelLoaded] = useState(false);

    const handleDocumentLoad = async (pdfObject: PDFDocumentProxy) => {
        setPageWidth(props.originalWidth);
        setPageHeight(props.originalHeight);
    };

    function handlePageLoad() {
        setRightPanelLoaded(true);
    }

    function calculateNewDimensions(
        height_origine: number,
        width_origine: number,
        height_cible: number,
        width_cible: number
    ): { newHeight: number, newWidth: number } {
        const heightRatio = height_cible / height_origine;
        const widthRatio = width_cible / width_origine;
        const scaleFactor = Math.min(heightRatio, widthRatio);
        const newHeight = height_origine * scaleFactor;
        const newWidth = width_origine * scaleFactor;

        return {newHeight, newWidth};
    }


    return (
        // <div className={"container-layout"}>
        <div className={"container-form-preview"}>
            <div className={"container-preview"}>
                <form style={{height: "100%"}} onSubmit={props.handleSubmit}>
                    <div className={"haut-ahi"}>
                        <>{props.formElement}</>
                    </div>
                    <div className={"bas-ahi"}>
                        <BouttonTelechargerRow setRightPanelLoaded={setRightPanelLoaded}
                                               label={translations.generer_poster}/>
                    </div>
                </form>
            </div>


            <div className="container-preview">
                {rightPanelLoaded &&
                    <>
                        <div className={"haut-ahi"}>
                            <div className={"poster-view"}>
                                <SizeMe monitorHeight={true}>
                                    {({size}) => (
                                        <>
                                            {/*{size?.width!} et {size?.height!}*/}
                                            <Document onLoadSuccess={handleDocumentLoad} error={""} loading={""}
                                                      file={baseUrl + "/" + props.posterPath}>
                                                <Page renderTextLayer={false} renderAnnotationLayer={false}
                                                      onLoadSuccess={handlePageLoad} loading={""}
                                                      canvasBackground={"transparent"} pageNumber={1}
                                                      height={calculateNewDimensions(pageHeight, pageWidth, size?.height!, size?.width!).newHeight}
                                                      width={calculateNewDimensions(pageHeight, pageWidth, size?.height!, size?.width!).newWidth}
                                                />
                                            </Document>
                                        </>
                                    )}
                                </SizeMe>
                            </div>
                        </div>
                        <div className={"bas-ahi"}>
                            <div className={"deux-boutons"}>
                                <BouttonCommanderRow label={translations.commander}/>
                                <BouttonAjouterGalerieRow filename={props.posterPath}
                                                          label={translations.ajouter_galerie}/>
                            </div>
                        </div>

                    </>
                }
            </div>

        </div>
        // </div>
    );
};