import React, {ReactNode} from 'react';
import 'react-tabs/style/react-tabs.css';
import PosterGenerator from './poster/generator/poster/PosterGenerator';
import PoemeGenerator from './poster/generator/poem/PoemGenerator';
import Header from "./poster/components/Header";
import {createBrowserRouter, Outlet, RouterProvider} from "react-router-dom";
import './App.sass'
import {FaPinterest} from "react-icons/fa";
import {SiRedbubble} from "react-icons/si";
import {LangueProvider, useLangue} from './LangueContexte';
import Galerie from "./poster/Galerie";
import {MenuProvider, useMenu} from "./MenuProvider";
import {MdEmail} from "react-icons/md";
import Domotique from "./domotique/Domotique";

const router = createBrowserRouter([
    {
        path: "/",
        element: <Application/>,
        children: [
            {
                path: "/",
                element: <PosterGenerator/>,
            },
            {
                path: "/poster",
                element: <PosterGenerator/>,
            },
            {
                path: "/galerie",
                element: <Galerie/>,

            },
            {
                path: "poeme",
                element: <PoemeGenerator/>,
            },
            {
                path: "domotique",
                element: <Domotique/>
            }
        ],
    },
]);

// export const MenuItem: FC<{ label: string }> = ({ label, children }) => {
//     const { selectedMenu, handleSelectMenu } = useMenu();
//     const isSelected = selectedMenu === label;
//
//     return (
//         <div
//             style={{ color: isSelected ? 'white' : 'black', cursor: 'pointer' }}
//             onClick={() => handleSelectMenu(label)}>
//             {children}
//         </div>
//     );
// };


function LienMenu(props: { to: string, label: string }) {
    const {selectedMenu, handleSelectMenu} = useMenu();
    const isSelected = selectedMenu === props.label;
    return (<a className={`menu-link menu-link-text ${isSelected ? 'selected-menu-item' : ''}`} onClick={() => {
        window.location.href = props.to
        handleSelectMenu(props.label)
    }}>{props.label}</a>)
    // return <Link onClick={() => {
    //     handleSelectMenu(props.label)
    //
    // }}  to>{props.label}</Link>;
}

function IconMenu(props: { url: string, icon: ReactNode }) {
    return <a target="_blank" href={props.url} className={"menu-link menu-link-icon"}>{props.icon}</a>;
}

function Layout() {
    return (<div className={"container-layout"}><Outlet/></div>);
}

function Application() {

    function Menu() {

        const {langue, translations, changerLangue} = useLangue();
        // const {selectedMenu, handleSelectMenu} = useMenu();

        return (
            <div className={"menu"}>
                <LienMenu to={"poster"} label={translations.posters}/>
                {/*<LienMenu to={"poeme"} label={translations.poems}/>*/}
                <LienMenu to={"domotique"} label={translations.domotique}/>
                {/*<LienMenu to={"galerie"} label={translations.galerie}/>*/}
                <IconMenu url={"mailto:contact.bamu@gmail.com"} icon={<MdEmail/>}/>
                {/*<IconMenu url={""} icon={<FaInstagramSquare/>}/>*/}
                <IconMenu url={"https://pin.it/4oM4yJZ9a"} icon={<FaPinterest/>}/>
                <IconMenu url={"https://Bamushi.redbubble.com"} icon={<SiRedbubble/>}/>
            </div>
        );
    }

    function FullContainer() {
        return <div className={"global"}>
            <Header/>
            <Menu/>
            <Layout/>
        </div>;
    }

    return (<LangueProvider>
        <MenuProvider>
            <FullContainer/>
        </MenuProvider>
    </LangueProvider>);


}

function App() {
    return (<RouterProvider router={router}/>);
}

export default App;
