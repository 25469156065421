// export const settingsCaracteres =
//     [ {
//         police: "YRDZST Medium.ttf",
//         nombre_cellule_largeur: 10,
//         nombre_cellule_hauteur: 10,
//         margin: 0,
//         largeur_poster: 1000,
//         hauteur_poster: 100,
//         color_hg: "#000000",
//         color_bg: "#000000",
//         color_hd: "#ffd500",
//         color_bd: "#ffd500",
//         background_color_1: "#ffffff",
//         taille_police_pinyin: 0,
//         taille_police_caractere: 1
//     },{
//         police: "YRDZST Medium.ttf",
//         nombre_cellule_largeur: 10,
//         nombre_cellule_hauteur: 10,
//         margin: 0,
//         largeur_poster: 100,
//         hauteur_poster: 1300,
//         color_hg: "#000000",
//         color_bg: "#000000",
//         color_hd: "#c90000",
//         color_bd: "#c90000",
//         background_color_1: "#ffffff",
//         taille_police_pinyin: 0,
//         taille_police_caractere: 1
//     }]

export const settingsCaracteres =
    [{
    police: "NotoSansSC-Black.ttf",
    nombre_cellule_largeur: 10,
    nombre_cellule_hauteur: 15,
    margin: 100,
    largeur_poster: 2600,
    hauteur_poster: 3900,
    color_hg: "#1a0000",
    color_bg: "#e4001a",
    color_hd: "#1a0000",
    color_bd: "#e4001a",
    background_color_1: "#ffc200",
    taille_police_pinyin: 0.2,
    taille_police_caractere: 0.7
}, {
    police: "XiaolaiMonoSC-Regular.ttf",
    nombre_cellule_largeur: 20,
    nombre_cellule_hauteur: 20,
    margin: 40,
    largeur_poster: 2080,
    hauteur_poster: 2080,
    color_hg: "#0000ff",
    color_bg: "#ff0000",
    color_hd: "#00ff00",
    color_bd: "#d3a800",
    background_color_1: "#ffffff",
    taille_police_pinyin: 0.4,
    taille_police_caractere: 0.6
}, {
        police: "YRDZST Medium.ttf",
        nombre_cellule_largeur: 10,
        nombre_cellule_hauteur: 10,
        margin: 0,
        largeur_poster: 1000,
        hauteur_poster: 1000,
        color_hg: "#000000",
        color_bg: "#000000",
        color_hd: "#0075c9",
        color_bd: "#0075c9",
        background_color_1: "#a8b9ff",
        taille_police_pinyin: 0,
        taille_police_caractere: 1
    },  {
    police: "HanyiSentyPagoda Regular.ttf",
    nombre_cellule_largeur: 12,
    nombre_cellule_hauteur: 18,
    margin: 20,
    largeur_poster: 600,
    hauteur_poster: 900,
    color_hg: "#005496",
    color_bg: "#005496",
    color_hd: "#005496",
    color_bd: "#000000",
    background_color_1: "#75be00",
    taille_police_pinyin: 0,
    taille_police_caractere: 0.9
    },  {
        police: "FZFangSong-Z02S Regular.ttf",
        nombre_cellule_largeur: 10,
        nombre_cellule_hauteur: 15,
        margin: 20,
        largeur_poster: 1040,
        hauteur_poster: 1540,
        color_hg: "#ffffff",
        color_bg: "#88caff",
        color_hd: "#88caff",
        color_bd: "#ffffff",
        background_color_1: "#29025d",
        taille_police_pinyin: 0,
        taille_police_caractere: 0.9
    }]


