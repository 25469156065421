export const settings_poeme = [{
    poeme: "poem-sympathy-for-peasants",
    police: "HanyiSentyPagoda Regular.ttf",
    margin: 20,
    largeur_poster: 540,
    hauteur_poster: 440,
    color_hg: "#353535",
    color_bg: "#353535",
    color_hd: "#353535",
    color_bd: "#353535",
    background_color_1: "#d30011",
    taille_police_caractere: 0.8,
    taille_police_pinyin: 0
}, {
    poeme: "poem-on-parting",
    police: "NotoSansSC-Bold.ttf",
    margin: 40,
    largeur_poster: 780,
    hauteur_poster: 480,
    color_hg: "#59e7ff",
    color_bg: "#A863FF",
    color_hd: "#A863FF",
    color_bd: "#59e7ff",
    background_color_1: "#00014e",
    taille_police_caractere: 0.75,
    taille_police_pinyin: 0
}]

// {
//     poeme: "poem-bmt",
//     police: "NotoSansSC-Bold.ttf",
//     margin: 0,
//     largeur_poster: 240,
//     hauteur_poster: 80,
//     color_hg: "#000000",
//     color_bg: "#000000",
//     color_hd: "#000000",
//     color_bd: "#000000",
//     background_color_1: "#e4001a",
//     taille_police_caractere: 1,
//     taille_police_pinyin: 0
// }, {
//     poeme: "poem-allez",
//     police: "NotoSansSC-Bold.ttf",
//     margin: 100,
//     largeur_poster: 1000,
//     hauteur_poster: 1000,
//     color_hg: "#000000",
//     color_bg: "#000000",
//     color_hd: "#000000",
//     color_bd: "#000000",
//     background_color_1: "#e4001a",
//     taille_police_caractere: 1,
//     taille_police_pinyin: 0
// }]